import React from 'react';
import './ProductStyle.css';

const BlackWire = ({ open,onClose }) => {
    

    if(!open) return null
    return (
        <div className='overlay'>
            <div className='proContainer'>
                <button onClick={onClose} className='closeBtn'>X</button>
                <p> Black Wire</p>
                <div className='specs'>
                    <ul>
                        <li>Raw Material: <div>Low Carbon steel, High Carbon steel</div></li>
                        <li>Dimensions Range: <div>⌀1mm - ⌀13mm</div></li>
                        <li>Packing: <div>Spools (300kg - 1000kg), Coils (50kg - 500kg), Straightened bars  </div></li>
                        <li>Applications: <div>Welded mesh, Nail manufacturing, Chain-link fences production</div></li>
                    </ul>
                </div>
                
            </div>
        </div>




    );
}

export default BlackWire;
