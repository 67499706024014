import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero' id='hero'>
            <div className='content'>
                {/* <p>y4y</p>
                <p>4y4w</p>
                <p>w4yt</p>
                <p>y64wy6</p>
                <button href='/' className='button'>Free</button> */}
            </div>
        </div>
    )
}

export default Hero
