import React, { useEffect, useRef, useState } from 'react';// import cli from './images/clientsimg2.png'
import './Clients.css'
import cli1 from './images/union.png'
import cli2 from './images/sallab.jpg'
import cli3 from './images/elaraby.jpg'
import cli4 from './images/kir.jpg'
import cli5 from './images/amf.jpg'
import cli6 from './images/ae.jpg'
import cli7 from './images/mobica.jpg'
import cli8 from './images/universal.jpg'
import cli9 from './images/fresh23.jpg'

const Clients = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const trackRef = useRef(null);
  const slideCount = 9; // Update based on the number of images

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prev => (prev + 1) % slideCount);
    }, 2000);

    return () => clearInterval(interval);
  }, [slideCount]);

  useEffect(() => {
    if (trackRef.current) {
      const cardWidth = trackRef.current.children[0].getBoundingClientRect().width;
      const moveAmount = -currentSlide * (cardWidth + 20); // 20 is the margin between cards
      trackRef.current.style.transform = `translateX(${moveAmount}px)`;
    }
  }, [currentSlide]);

  return (
    <div className='clients' id='clients'>
      <div className='container'>
        <h2>Clients</h2>
        <span className='line'></span>
        <div className='carousel'>
          <div className='carousel-track' ref={trackRef}>
            <div className='card'><img src={cli1} alt='client1' /></div>
            <div className='card'><img src={cli2} alt='client2' /></div>
            <div className='card'><img src={cli3} alt='client3' /></div>
            <div className='card'><img src={cli4} alt='client4' /></div>
            <div className='card'><img src={cli5} alt='client5' /></div>
            <div className='card'><img src={cli6} alt='client6' /></div>
            <div className='card'><img src={cli7} alt='client7' /></div>
            <div className='card'><img src={cli8} alt='client8' /></div>
            <div className='card'><img src={cli9} alt='client9' /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;

