import React from 'react';
import './ProductStyle.css';

const StitchingWire = ({ open,onClose }) => {
    

    if(!open) return null
    return (
        <div className='overlay'>
            <div className='proContainer'>
                <button onClick={onClose} className='closeBtn'>X</button>
                <p> Stitching Wire</p>
                <div className='specs'>
                    <ul>
                        <li>Raw Material: <div>Low Carbon steel, High Carbon steel</div></li>
                        <li>Dimensions Range: <div>: Width: 1.9mm – 2.5mm, Thickness: 0.5mm – 0.7mm  </div></li>
                        <li>Surface Quality: <div>Copper Coated or Galvanized </div></li>
                        <li>Packing: <div>: Spools 1kg – 4kg </div></li>
                    </ul>
                </div>
                
            </div>
        </div>
    );
}

export default StitchingWire;
