import React from 'react';
import './ProductStyle.css';

const CopperCoatedWeldingWire = ({ open,onClose }) => {
    

    if(!open) return null
    return (
        <div className='overlay'>
            <div className='proContainer'>
                <button onClick={onClose} className='closeBtn'>X</button>
                <p> Copper Coated Wires</p>
                <div className='specs'>
                    <ul>
                        <li>Raw Material: <div>Low Carbon steel, High Carbon steel</div></li>
                        <li>Dimensions Range: <div>1mm – 4mm</div></li>
                        <li>Surface Quality: <div>Copper coated </div></li>
                        <li>Packing: <div>Coils 100kg - 300kg, spools 50kg, straightened bars  </div></li>
                        <li> Applications: <div>Welding wires  </div></li>
                    </ul>
                </div>
                
            </div>
        </div>

    );
}

export default CopperCoatedWeldingWire;
