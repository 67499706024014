import React from 'react'
import './Footer.css'
import { Link } from 'react-scroll'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <ul>
                    <li className='nav-item'>
                        <Link to="hero" spy={true} smooth={true} offset={-100} duration={500} >Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="about" spy={true} smooth={true} offset={-10} duration={500} >About</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="products" spy={true} smooth={true} offset={-100} duration={500} >Products</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="clients" spy={true} smooth={true} offset={-120} duration={500} >Clients</Link>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to="certificates" spy={true} smooth={true} offset={-40} duration={500} >Certifications</Link>
                    </li> */}
                    {/* <li className='nav-item'>
                        <Link to="contact" spy={true} smooth={true} offset={-100} duration={500}>Contact</Link>
                    </li> */}
                </ul>
                <div className='bottom'>
                    <span className='line'></span>
                    <p>2024 AbulHoul, Inc. All rights reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
