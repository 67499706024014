import React from 'react';
import './ProductStyle.css';

const BlackAnnealed = ({ open,onClose }) => {
    

    if(!open) return null
    return (
        <div className='overlay'>
            <div className='proContainer'>
                <button onClick={onClose} className='closeBtn'>X</button>
                <p> Black Annealed</p>
                <div className='specs'>
                    <ul>
                        <li>Raw Material: <div>Low Carbon steel</div></li>
                        <li>Dimensions Range: <div>⌀1mm - ⌀10mm</div></li>
                        <li>Surface Quality: <div>Pickling Special coating</div></li>
                        <li>Packing: <div>Coils (20kg - 500kg) </div></li>
                        <li>Applications: <div>Nails and Screws</div></li>
                    </ul>
                </div>
                
            </div>
        </div>
    );
}

export default BlackAnnealed;
