import React, { useState } from 'react'
import pro1 from './images/flatwires.jpg'
import pro2 from './images/brightWire.jpg'
import pro3 from './images/blackwire.jpg'
import pro4 from './images/hotannealed.jpg'
import pro5 from './images/StraightenedWires.jpg'
import pro6 from './images/coppercoated.jpg'
import pro7 from './images/BaleWire.jpg'
import pro8 from './images/StitchingWire.png'
import pro9 from './images/fence1.jpg'
import pro10 from './images/welded-wire-mesh.jpg'
import './Products.css'
import FlatWiresAndProfiles from './products/FlatWiresAndProfiles'
import BrightWire from './products/BrightWire'
import BaleWire from './products/BaleWire'
import BlackAnnealed from './products/BlackAnnealed'
import BlackWire from './products/BlackWire'
import ChainLinkFence from './products/ChainLinkFence'
import CopperCoatedWeldingWire from './products/CopperCoatedWeldingWires'
import StitchingWire from './products/StitchingWire'
import StraightenedWiresBars from './products/StraightenedWiresBars'
import WeldedWireMesh from './products/WeldedWireMesh'

const Products = () => {
    const [openFlatWiresAndProfiles, setOpenFlatWiresAndProfiles] = useState(false);
    const [openBrightWire, setOpenBrightWire] = useState(false);
    const [openBlackWire, setOpenBlackWire] = useState(false);
    const [openBlackAnnealed, setOpenBlackAnnealed] = useState(false);
    const [openStraightenedWiresBars, setOpenStraightenedWiresBars] = useState(false);
    const [openCopperCoatedWeldingWires, setOpenCopperCoatedWeldingWires] = useState(false);
    const [openBaleWire, setOpenBaleWire] = useState(false);
    const [openStitchingWire, setOpenStitchingWire] = useState(false);
    const [openChainLinkFence, setOpenChainLinkFence] = useState(false);
    const [openWeldedWireMesh, setOpenWeldedWireMesh] = useState(false);



    const handleClick1 = () => {
        setOpenFlatWiresAndProfiles(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose1 = () => {
        setOpenFlatWiresAndProfiles(false)
        document.body.style.overflow = 'scroll';
      };
    
    const handleClick2 = () => {
        setOpenBrightWire(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose2 = () => {
        setOpenBrightWire(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick3 = () => {
        setOpenBlackWire(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose3 = () => {
        setOpenBlackWire(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick4 = () => {
        setOpenBlackAnnealed(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose4 = () => {
        setOpenBlackAnnealed(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick5 = () => {
        setOpenStraightenedWiresBars(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose5 = () => {
        setOpenStraightenedWiresBars(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick6 = () => {
        setOpenCopperCoatedWeldingWires(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose6 = () => {
        setOpenCopperCoatedWeldingWires(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick7 = () => {
        setOpenBaleWire(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose7 = () => {
        setOpenBaleWire(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick8 = () => {
        setOpenStitchingWire(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose8 = () => {
        setOpenStitchingWire(false)
        document.body.style.overflow = 'scroll';
      };

      const handleClick9 = () => {
        setOpenChainLinkFence(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose9 = () => {
        setOpenChainLinkFence(false)
        document.body.style.overflow = 'scroll';
      };
      
      const handleClick10 = () => {
        setOpenWeldedWireMesh(true)
        document.body.style.overflow = 'hidden';
      };

      const handleClose10 = () => {
        setOpenWeldedWireMesh(false)
        document.body.style.overflow = 'scroll';
      };
    

    return (
        <div className='products' id='products'>
            <div className='container'>
                <h2>Products</h2>
                <span className='line'></span>
                {/* <FlatWiresAndProfiles open={openFlatWiresAndProfiles} onClose={() => setOpenFlatWiresAndProfiles(false)} /> */}
                <FlatWiresAndProfiles open={openFlatWiresAndProfiles} onClose={handleClose1} />
                <BrightWire open={openBrightWire} onClose={handleClose2} />
                <BlackWire open={openBlackWire} onClose={handleClose3} />
                <BlackAnnealed open={openBlackAnnealed} onClose={handleClose4} />
                <StraightenedWiresBars open={openStraightenedWiresBars} onClose={handleClose5} />
                <CopperCoatedWeldingWire open={openCopperCoatedWeldingWires} onClose={handleClose6} />
                <BaleWire open={openBaleWire} onClose={handleClose7} />
                <StitchingWire open={openStitchingWire} onClose={handleClose8} />
                <ChainLinkFence open={openChainLinkFence} onClose={handleClose9} />
                <WeldedWireMesh open={openWeldedWireMesh} onClose={handleClose10} />


                <div className='content'>
                    <div className='card' onClick={handleClick1}>
                        <img src={pro1} alt='product1'/>
                        <label><span>Flat Wires and Profiles</span></label>
                        <p>Abulhoul's Flat Wires and Profiles are made of low-carbon steel.</p>
                    </div>
                    <div className='card' onClick={handleClick2}>
                        <img src={pro2} alt='product2'/>
                        <label><span>Cold Drawn Bright Wire</span></label>
                        <p>Our Cold Drawn Bright Wire is a versatile and high-quality product that is made from both Low carbon steel and High carbon steel.</p>
                    </div>
                    <div className='card' onClick={handleClick3}>
                        <img src={pro3} alt='product3'/>
                        <label><span>Cold Drawn Black Wire</span></label>
                        <p>Our Cold Drawn Black Wire is a versatile and high-quality product that is made from both Low carbon steel and High carbon steel.</p>
                    </div>
                    <div className='card' onClick={handleClick4}>
                        <img src={pro4} alt='product4'/>
                        <label><span>Black Annealed Wire</span></label>
                        <p>Our Black Annealed Wire is made from high-quality low carbon steel wire.</p>
                    </div>
                    <div className='card' onClick={handleClick5}>
                        <img src={pro5} alt='product5'/>
                        <label><span>Straightened Wires And Bars</span></label>
                        <p>Our Straightened Wires & Bars are made from high-quality low carbon drawn steel wire.</p>
                    </div>
                    <div className='card' onClick={handleClick6}>
                        <img src={pro6} alt='product6'/>
                        <label><span>Copper Coated Welding Wires</span></label>
                        <p>Our company offers high-quality Copper Coated Welding Wires made of low-carbon steel. </p>
                    </div>
                    <div className='card' onClick={handleClick7}>
                        <img src={pro7} alt='product7'/>
                        <label><span>Bale Wire</span></label>
                        <p>Our Bale Wire is made from high-quality low carbon annealed wire, making it strong and suitable for use in a wide range of applications.</p>
                    </div>
                    
                    <div className='card'  onClick={handleClick8}>
                        <img src={pro8} alt='product8'/>
                        <label><span>Stitching Wire</span></label>
                        <p>Our Carton Staples are made from high-quality steel wire with a copper-plated or galvanized coating.</p>
                    </div>
                    <div className='card' onClick={handleClick9}>
                        <img src={pro9} alt='product9'/>
                        <label><span>Chain Link Fence</span></label>
                        <p>Our chain link fences are PVC or Galvanized coated and are used in securing and enclosing places such as backyards, construction sites, farms, etc. </p>
                    </div>
                    <div className='card' onClick={handleClick10}>
                        <img src={pro10} alt='product10'/>
                        <label><span>Welded Wire Mesh</span></label>
                        <p>Our welded wire mesh is an extremely versatile product which is produced from our low carbon steel. It is also available in numerous sizes and meshes opening shapes.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Products
