import React from 'react'
import john from './images/about1.jpg'
import './About.css'

const About = () => {
    return (
        <div className='about' id='about'>
            <div className='container'>
                <img src={john} alt='john' />
                <div className='col-2'>
                    <h2>About</h2>
                    <span className='line'></span>
                    <p>
                    The company, founded in 1981, is a third-generation family business with over
50 years of experience in the industry. This extensive history, combined with a
passion for our work, has propelled the company to its current position of
success within the industry. This experience and passion have allowed the
company to establish a strong reputation, built on a foundation of quality and
reliability.Our customers can trust that the company will be
around for many years to come and continue to provide the same high level of
service.
                    </p>
                    <p>The extensive range of production means enable Abulhoul wire products
to be manufactured in accordance with specific customer requests and
expectations; the company guarantees high product quality, suitable
packing, timely delivery & most importantly customer satisfaction with
the help of its experienced and dynamic team.</p>
                </div>
            </div>
        </div>
    )
}

export default About
