import React from 'react'
import iso from './images/iso3.png'
import './Certificates.css'

const Certificates = () => {
    return (
        <div className='certificates' id='certificates'>
            <div className='container'>
            <img src={iso} alt='iso' />
            <div className='col-2'>
            <h2>Certificates</h2>
                
                    <span className='line'></span>
                    <p>
                        ISO 9001
                        <br />
                        Status: Certified
                        <br />
                        The international standard for a quality management system.
                        <br />
                        <br />
                        ISO 14001
                        <br />
                        Status: Pending approval
                        <br />
                        The international standard for an environmental management system.
                        <br />
                        <br />
                        ISO 45001
                        <br />
                        Status: Pending approval
                        <br />
                        The international standard for an occupational health and safety management system.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Certificates