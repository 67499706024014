import React from 'react';
import './ProductStyle.css';

const BaleWire = ({ open,onClose }) => {
    

    if(!open) return null
    return (
        <div className='overlay'>
            <div className='proContainer'>
                <button onClick={onClose} className='closeBtn'>X</button>
                <p> Bale Wire</p>
                <div className='specs'>
                    <ul>
                        <li>Raw Material: <div>Low Carbon steel</div></li>
                        <li>Dimensions Range: <div>⌀1mm - ⌀3mm</div></li>
                        <li>Surface Quality: <div>Dipped in oil, Dry </div></li>
                        <li>Packing: <div>Spools (25kg - 50kg) </div></li>
                        
                    </ul>
                </div>
                
            </div>
        </div>

    );
}

export default BaleWire;
