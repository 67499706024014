import React from 'react';
import './ProductStyle.css';

const WeldedWireMesh = ({ open,onClose }) => {
    if(!open) return null
    return (
        <div className='overlay'>
            <div className='proContainer'>
                <button onClick={onClose} className='closeBtn'>X</button>
                <p> Welded Wire Mesh</p>
                <div className='specs'>
                    <ul>
                        <li>Raw Material: <div>Low Carbon steel</div></li>
                        <li>Dimensions Range: <div>Customized </div></li>
                        <li>Surface Quality: <div>Galvanized – Painted – Black </div></li>
                        <li>Packing: <div>Customized </div></li>
                    </ul>
                </div>
                
            </div>
        </div>
    );
}
export default WeldedWireMesh;
