import React from 'react'
// import iso from './images/iso3.png'
import './Contact.css'

const Contact = () => {
    return (
        <div className='contact' id='contact'>
            <div className='container'>
                <div className='col-1'>
                    <p className='title'>Head office</p>
                    <p className='body'>Elkabary industrial zone, moharam-bay, Alexandria, Egypt. <br></br>
                    Tel: +201003066308, +201221070706, +201000520904 <br></br>
                    Fax: +2033618059<br></br>
                    Email: info@ghanem-group.net <br></br>
                    Website: ghanem-group.net</p>
                    
                    <p className='title'>UAE Branch</p> 
                    <p className='body'>Branch: Tamy Trading LLC <br></br>
                    Address: Al Shohada Road, Al Hamra Industrial Zone-FZ, RAK, UAE.<br></br>
                    Tel : +971557476830<br></br>
                    Email: mtghanem@ghanem-group.net</p>

                    <p className='title'>Factory</p>
                    <p className='body'>Alex-cairo desert road km 20, Merghem industrial zone, Alexandria, Egypt.<br></br>
                    The international standard for an environmental management system.</p>
                    
                    <p className='title'>Sales Point</p>
                    <p className='body'>Qalyob agriculture service road, Qalyobia, Egypt.<br></br>
                    The international standard for an occupational health and safety management system.</p>
                </div>
                <div className='col-2'>
                    <iframe width='570' height='420' src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6827.407133168626!2d29.904862000000005!3d31.173499999999997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdafe0610ffd3c87d!2zQWJ1bGhvdWwgSW5kdXN0cmlhbCAmVHJhZGluZyBjby4gKEdoYW5lbSBncm91cCkg2LTYsdmD2Kkg2KfYqNmIINin2YTZh9mI2YQg2KfZhNi12YbYp9i52YrYqSDYp9mE2KrYrNin2LHZitipICjYutin2YbZhSDZhNmE2KfYs9mE2KfZgyk!5e0!3m2!1sen!2seg!4v1664576918869!5m2!1sen!2seg' title='map' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
            </div>
        </div>
    )
}

export default Contact
